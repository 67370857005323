/**
 * 账户数据服务
 * @author Jianbo
 * @time 2020-03-06
 */
import api from '../api';
import axios from '../axios';

export class AccountService {
  // 获取用户信息
  static getUserInfo() {
    return axios.get(api.account.userInfo);
  }

  // 快捷登录获取验证码
  static sendLoginSms({ tel }) {
    return axios.post(api.account.fastLoginSendcode, { tel });
  }

  // 更新信息
  static update(data = {}) {
    return axios.post(api.account.setting, data);
  }

  // 修改密码
  static changePassword({ pwd, repwd }) {
    return axios.post(api.account.changePassword, { pwd, repwd });
  }

  static logout() {
    return axios.post(api.account.logout);
  }
}

export default AccountService;
