import NotFound from '../views/404.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/account/login.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/regist',
    name: 'Regist',
    component: () => import('../views/account/regist.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/account/auth.vue'),
  },
  {
    path: '/sso/client',
    name: 'SsoClient',
    component: () => import('../views/sso/client.vue'),
  },
  {
    path: '/sso/ticket',
    name: 'SsoTicket',
    component: () => import('../views/sso/ticket.vue'),
  },
  {
    path: '/profile',
    component: () => import('../views/profile/layout.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'ProfileHome',
        component: () => import('../views/profile/index.vue'),
      },
      {
        path: 'avatar',
        name: 'ProfileAvatar',
        component: () => import('../views/profile/avatar.vue'),
      },
      {
        path: 'nickname',
        name: 'ProfileNickname',
        component: () => import('../views/profile/nickname.vue'),
      },
      {
        path: 'sex',
        name: 'ProfileSex',
        component: () => import('../views/profile/sex.vue'),
      },
      {
        path: 'birthday',
        name: 'ProfileBirthday',
        component: () => import('../views/profile/birthday.vue'),
      },
      {
        path: 'password',
        name: 'ProfilePassword',
        component: () => import('../views/profile/password.vue'),
      },
      {
        path: 'tel',
        name: 'ProfileTel',
        component: () => import('../views/profile/tel.vue'),
      },
    ],
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

export default routes;
