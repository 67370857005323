import axios from 'axios';
import { TokenStorageFactory } from '../factory/TokenStorageFactory';

const instance = axios.create({
  // baseURL: ''
});

// http request 拦截器
instance.interceptors.request.use(
  (config) => {
    const token = TokenStorageFactory.getToken();
    const con = config;
    if (token) {
      con.headers.Authorization = token;
    }
    return con;
  },
  (err) => Promise.reject(err),
);

export default instance;
