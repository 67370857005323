/**
 * api config
 */
const BASE_URL = '/api';
const COMMUNITY = `${BASE_URL}/community/public`;
const RENTAL = `${BASE_URL}/rental/public`;

export default {
  account: {
    userInfo: `${RENTAL}/User/User/find`,
    loginByPhone: `${RENTAL}/Account/login`,
    logout: `${RENTAL}/Account/logout`,
    fastLoginSendcode: `${RENTAL}/User/Account/fastLoginSendcode`,
    fastLogin: `${RENTAL}/Account/fastLogin`,
    loginbywfccode: `${COMMUNITY}/wechat/loginbywfccode`,
    regist: `${RENTAL}/User/Account/add`,
    registerSendCode: `${RENTAL}/User/Account/registerSendcode`,
    setting: `${COMMUNITY}/user/setting`,
    changePassword: `${RENTAL}/User/User/pUpdatePwd`,
  },
  // 单点登录
  sso: {
    // 获取凭证
    getTicket: `${COMMUNITY}/sso/getticket`,
    // 凭证换取token
    getToken: `${COMMUNITY}/sso/gettoken`,
  },
};
