import { AccountFactory } from '../factory/AccountFactory';

export const permision = {
  /**
   * 登录权限校验
   * @return  Boolean
   */
  auth() {
    return AccountFactory.isLoggedin();
  },
  /**
   * 页面权限判断
   * @return  Boolean
   * @todo
   */
  hasPermision() {
    return true;
  },
};

export default permision;
