/**
 * 当前登录用户类
 * @author  Jianbo
 */

class UserInfo {
  'user_id' = 0

  nickname = ''

  sex = 0

  avatar = ''

  constructor(params) {
    this.setData(params);
  }

  /**
   * 设置属性
   * @param  Object  obj  属性对象, 如: {id: 10001}
   * @return Class   this
  */
  setData(obj = {}) {
    Object.keys(obj).forEach((k) => {
      if (typeof obj[k] !== 'function') this[k] = obj[k];
    });
    return this;
  }

  format() {
    return this;
  }

  // // 处理性别
  // initSexName() {
  //   let s = parseInt(this.sex)
  //   this.sex_name = s === MALE ? '男' : (s === FEMALE ? '女' : '未知')
  // }
  //
  // // 处理头像
  // initAvatar() {
  //   let src = this.avatar ? this.avatar : DEFAULT_AVATAR
  //   this.avatar = addImageSuffix(src, 'small')
  // }
  //
  // initTel() {
  //   this.tel_private = this.tel ? this.tel.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3") : null
  // }
}

export default UserInfo;
