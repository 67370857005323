/**
 * 账户相关
 * @author Jianbo
 * @time   2020-02-25
 */
import { TokenStorageFactory } from './TokenStorageFactory';
import UserInfoStorage from '../storage/UserInfoStorage';
import { AccountService } from '../service/AccountService';
import UserInfo from '../model/UserInfo';
import store from '../store';

export class AccountFactory {
  /**
   * 获取当前登录token
   * @return String
   */
  static getToken() {
    // todo
    return store.state.token ? store.state.token : TokenStorageFactory.getToken();
  }

  /**
   * 是否已登录
   * @return Boolean
   */
  static isLoggedin() {
    // 从vuex读取token
    let { token } = store.state;
    if (!token) {
      token = TokenStorageFactory.getToken();
      try {
        AccountFactory.initLoginUser();
      } catch (e) {
        // 无法获取用户信息
      }
    }
    return token && token.length > 1;
  }

  /**
   * 初始化等用户信息
   * 每次启动时
   */
  static initLoginUser() {
    // 本地token 放到 store中
    const token = TokenStorageFactory.getToken();
    store.dispatch('setToken', { token });
    AccountFactory.initUserInfo();
  }

  /**
   * 重置用户信息
   */
  static initUserInfo() {
    AccountService.getUserInfo().then((res) => {
      if (res.data.code === 200) {
        AccountFactory.setCurrentUser(res.data.data);
      }
    });
  }

  /**
   * 设置当前登录用户信息
   * @param Object  userData
   * @return Boolean
   */
  static setCurrentUser(userData) {
    const user = userData ? new UserInfo(userData) : null;
    return UserInfoStorage.save(user);
  }

  /**
   * 获取当前用户等的信息
   * @return  Object CurrentUserInfo
   */
  static getCurrentUser() {
    return UserInfoStorage.getUserInfo();
  }

  static updateProfile(data = {}) {
    return AccountService.update(data).then((res) => {
      if (res.data.code === 1) {
        return Promise.resolve('success');
      }
      return Promise.reject(new Error(res.data.msg || '网络错误'));
    });
  }

  static logout() {
    AccountService.logout();
    try {
      store.dispatch('clearToken');
    } catch (e) {
      // do nothing
    }
    try {
      TokenStorageFactory.clearToken();
    } catch (e) {
      // do nothing
    }
  }
}

export default AccountFactory;
