import Vue from 'vue';
import VueRouter from 'vue-router';
// 进度条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
// 路由配置
import routes from './routes';
// 权限
import permission from './permission';

Vue.use(VueRouter);
// 站点名
const DOCUMENT_TITLE_SUFFIEX = '21世纪房车网';

// 进度条
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes,
});

// Before Router Enter
router.beforeEach((to, from, next) => {
  NProgress.start();
  // 设置标题
  if (typeof to.meta.title !== 'undefined') {
    document.title = to.meta.title ? to.meta.title : DOCUMENT_TITLE_SUFFIEX;
  }

  // 是否需要登录
  const requireAuth = to.matched.some((r) => r.meta.auth);
  // 是否已登录
  const isLoggedin = permission.auth();
  if (requireAuth && !isLoggedin) {
    next({
      name: 'Login',
      query: { from: to.fullPath },
    });
  } else {
    next();
  }
});

router.afterEach(() => {
  // 滚动到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  NProgress.done();
});

export default router;
