<template lang="html">
  <div class="not-found-container">
    <div class="no-found-image">
      <img src="http://cdn.wanfangche.com/assets/images/404-not-found.png" alt="">
    </div>
    <div class="">
      404 页面未找到
    </div>
    <div class="home-btn">
      <a @click="$router.go(-1)">返回</a>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="css" scoped>
  .not-found-container {
    max-width: 375px;
    padding: 40px 0;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    color: #CDD5DF;
    .no-found-image {
      width: 120px;
      margin: 20px auto;
      display: block;
      text-align: center;
      img {
        width: 100%;
      }
    }
    .home-btn {
      padding: 20px 0;
      margin: 10px auto;
      text-align: center;
    }
    .home-btn > a {
      color: #31C27C;
    }
  }
</style>
