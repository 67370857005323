<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script type="text/javascript">

export default {
};
</script>

<style>
@import './css/bootstrap.min.css';
  @import './css/font-awesome.min.css';
  @import './css/animate.css';
  @import './css/transition.css';
  @import './css/style.css';
  /* 进度条颜色 */
  #nprogress .bar {
     background: #31C27C !important;
   }
</style>
