/**
 * 用户信息存储类
 * 用户信息保存到 store 中
 * @author Jianbo
 */
import store from '../store';

class UserInfoStorage {
  /**
   * 保存用户信息
   */
  static save(userInfo) {
    store.dispatch('setUserInfo', userInfo);
  }

  /**
   * 获取用户信息
   * @return UserInfo
   */
  static getUserInfo() {
    return store.state.userInfo;
  }
}

export default UserInfoStorage;
