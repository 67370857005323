/**
 * Token 本地存储
 * 存放在 localStorage
 * @author Jianbo
 * @time   2020-02-25
 */
import Cookies from 'js-cookie';
import { AUTH_TOKEN_KEY } from './config';

class TokenLocalStorage {
  /**
   * 获取token
   * @return  String
  */
  static getToken() {
    let token = null;
    try {
      token = Cookies.get(AUTH_TOKEN_KEY);
    } catch {
      // do nothing
    }

    // try {
    //   return window.localStorage.getItem(AUTH_TOKEN_KEY);
    // } catch (e) {
    //   // do nothing
    // }

    return token;
  }

  /**
   * 保存token
   * @param  String { token }
   * @return undefined
   */
  static saveToken({ token }) {
    try {
      return Cookies.set(AUTH_TOKEN_KEY, token);
      // return localStorage.setItem(AUTH_TOKEN_KEY, token)
    } catch (e) {
      // do nothing
    }
    return null;
    // return window.localStorage.setItem(AUTH_TOKEN_KEY, token);
  }

  /**
   * 清除token
   */
  static clearToken() {
    try {
      return Cookies.remove(AUTH_TOKEN_KEY, { path: '/' });
      // return localStorage.removeItem(AUTH_TOKEN_KEY)
    } catch (e) {
      // do nothing
    }
    return null;
    // return window.localStorage.removeItem(AUTH_TOKEN_KEY);
  }
}

export default TokenLocalStorage;
