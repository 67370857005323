/**
 * Token存储
 * @author Jianbo
 * @time   2020-02-25
 */
import TokenLocalStorage from '../storage/TokenLocalStorage';

export class TokenStorageFactory {
  /**
   * 获取token
   * @return  String
  */
  static getToken() {
    return TokenLocalStorage.getToken();
  }

  /**
   * 保存token
   */
  static saveToken({ token }) {
    return TokenLocalStorage.saveToken({ token });
  }

  /**
   * 清除token
   */
  static clearToken() {
    return TokenLocalStorage.clearToken();
  }
}

export default TokenStorageFactory;
