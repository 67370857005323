import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    userInfo: null,
  },
  getters: {
    token: (state) => state.token,
    isLoggedin: (state) => {
      const hasToken = Boolean(state.token && state.token.length > 0);
      return hasToken;
    },
    userInfo: (state) => state.userInfo,
  },
  mutations: {
    SEVE_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SAVE_TOKEN(state, { token }) {
      state.token = token;
    },
    CLEAR_TOKEN(state) {
      state.token = null;
    },
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      commit('SEVE_USER_INFO', userInfo);
    },
    setToken({ commit }, { token }) {
      commit('SAVE_TOKEN', { token });
    },
    clearToken({ commit }) {
      commit('CLEAR_TOKEN');
    },
  },
  modules: {
  },
});
